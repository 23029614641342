import {Injectable} from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import {fromEvent, Observable, throwError} from 'rxjs'
import {mapTo, retryWhen, switchMap} from 'rxjs/operators';
import {AppOfflineService} from '../app-offline/app-offline.service';


@Injectable()
export class WcbOfflineInterceptor implements HttpInterceptor {

    private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true))
    private failedRequests: string[] = []

    constructor(private offlineService: AppOfflineService) {
    }

    get isOnline() {
        return navigator.onLine
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.onlineChanges$.subscribe(() => {
            if (this.isOnline) {
                this.offlineService.close()
            }
        })

        return next.handle(req).pipe(
            retryWhen(errors => {
                if (this.isOnline) {
                    return errors.pipe(switchMap(err => throwError(err)))
                } else {
                    this.failedRequests.push(req.url)
                }
                this.offlineService.open()
                return this.onlineChanges$
            })
        )
    }

}
