import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable()
export class WcbAuthInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const authToken = localStorage.getItem(environment.authHeaderName) || '';

        // request
        const authReq = req.clone({
            headers: req.headers.set(environment.authHeaderName, authToken)
        });

        return next.handle(authReq).pipe(
            tap(evt => {
                // response
                if (evt instanceof HttpResponse && !evt.body.type) {
                    if (evt.body._meta && evt.body._meta[environment.authHeaderName]) {
                        const newAuthToken = evt.body._meta[environment.authHeaderName] || '';
                        if (newAuthToken) {
                            localStorage.setItem(environment.authHeaderName, newAuthToken);
                        }
                    }
                }
            })
        );
    }
}
