import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UpdaterPromptComponent} from './updater-prompt.component';
import {catchError, first} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})

export class UpdaterService {

    private isPromptOpen = false;

    constructor(private http: HttpClient, private dialog: MatDialog) {

    }

    initVersionChecker() {
        setInterval(() => {
            this.checkCurrentVersion();
        }, 30 * 60 * 1000)
    }

    checkCurrentVersion(): void {
        if (!this.isPromptOpen) {
            this.http.get('/assets/version.json?t=' + new Date().getTime()).pipe(
                first(),
                catchError((err) => {
                    return of(false);
                })
            )
                .subscribe((resp: any) => {
                        if (resp && resp.version && (resp.version + (!environment.production ? '-dev' : '')) !== environment.appVersion) {
                            console.log('App needs to update: ' + environment.appVersion + ' => ' + resp.version);
                            this.isPromptOpen = true;
                            const ref: MatDialogRef<UpdaterPromptComponent> = this.dialog.open(UpdaterPromptComponent, {
                                disableClose: true
                            });

                            ref.afterClosed().subscribe(
                                (answer: boolean) => {
                                    if (answer) {
                                        location.reload();
                                    }
                                    this.isPromptOpen = false;
                                }
                            )
                        }
                    }
                )
        }
    }

}

