import {Router, RouterModule, Routes} from '@angular/router'
import {AuthLayoutComponent} from './shared/components/layouts/auth-layout/auth-layout.component'
import {wcbAuthenticationGuard} from './shared/services/auth/authentication.guard'
import {wcbAuthorizationGuard} from './shared/services/auth/authorization.guard'
import {Injector, NgModule} from '@angular/core';
import {RouterConfigService} from './shared/services/router-config.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {FrontLayoutComponent} from './shared/components/layouts/front-layout/front-layout.component';
import {CookieOverviewComponent} from './views/cookie/cookie-overview.component';
import {WcbUserService} from './shared/services/auth/user.service';
import {RouterConfigEditorService} from './shared/services/router-config-editor.service';
import {NotFoundComponent} from "./views/public/not-found/not-found.component";
import {Settings} from "luxon";

const rootRouter: Routes = [
    {
        path: 'sec',
        canActivate: [wcbAuthenticationGuard, wcbAuthorizationGuard],
        canActivateChild: [wcbAuthenticationGuard, wcbAuthorizationGuard],
        loadChildren: () => import('./views/secured/secured.module').then(m => m.SecuredModule),
    },
    {
        path: 'pub',
        component: AuthLayoutComponent,
        loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule)
    },
    {
        path: 'cookie',
        component: FrontLayoutComponent,
        children: [
            {
                path: '',
                component: CookieOverviewComponent
            }
        ]
    },
    {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(rootRouter, {
            useHash: false,
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [RouterModule]
})

export class AppRouting {
}

export function configServiceFactory(
    injector: Injector,
    routerConfigService: RouterConfigService,
    routerConfigEditorService: RouterConfigEditorService,
    translate: TranslateService,
    wcbUserService: WcbUserService
) {
    return () => {

        // Translator init from browser settings
        let browserLang: string | false = translate.getBrowserLang() || 'cs'
        const availableLanguages = environment.languages || ['cs']
        browserLang = availableLanguages.includes(browserLang) ? browserLang : false
        translate.addLangs(availableLanguages)
        translate.setDefaultLang(availableLanguages[0])

        // set language from url
        let urlLanguage: string | false = false
        const langRegex = new RegExp('^\/(' + availableLanguages.join('|') + ')\/?(.*)$', 'gmy')
        if (window.location.pathname.match(langRegex)) {
            urlLanguage = window.location.pathname.substring(1, 3)
            history.replaceState(null, '', '/' + window.location.pathname.replace(langRegex, `$2`))
        }

        // or localstorage
        const localStorageLang = localStorage.getItem('lang')
        const userLanguage = localStorageLang && availableLanguages.includes(localStorageLang) ? localStorageLang : false

        translate.use(urlLanguage || userLanguage || browserLang || availableLanguages[0])
        Settings.defaultLocale = urlLanguage || userLanguage || browserLang || availableLanguages[0]

        localStorage.setItem('lang', urlLanguage || userLanguage || browserLang || availableLanguages[0])

        // router settings - loads data by itself, user preload
        const dynamicRoutes = []
        if (environment.module.web || environment.module.gallery) {
            dynamicRoutes.push(routerConfigService.addDynamicRoutes(injector.get(Router), ''),
                routerConfigEditorService.addDynamicRoutes(injector.get(Router), 'web')) // should be called by MODULE_INITIALIZER...
        } else {
            dynamicRoutes.push(routerConfigService.addOnlySecuredRoute(injector.get(Router)))
        }
        return Promise.all([
            ...dynamicRoutes,
            wcbUserService.tryInitUserAndAclByToken()])
    }
}

